import { Grid, Box, IconButton } from "@mui/material";
import { BLACK, LIGHTGREY } from "../../store/colorCode";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SETVALUE } from "../../store/actions";
import {
  COMMENTARY,
  HIGHLIGHT,
  INFOGRAPHICS,
  PARALLELBIBLE,
  READINGPLANS,
  SIGNBIBLE,
} from "../../store/views";
import { connect } from "react-redux";
import { styled } from "@mui/system";

const Icon = styled("i")({
  padding: "10px",
  color: BLACK,
});

const FeaturesBox = (props) => {
  const { setParallelView } = props;
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        fontSize: { lg: 20, xs: 16 },
        textAlign: "center",
        backgroundColor: LIGHTGREY,
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <h1> {t("featuresDesc1")}</h1>
      </Box>
      <Box
        justifyContent="center"
        sx={{
          display: "flex",
          flexGrow: 1,
          paddingBottom: "50px",
          fontSize: "1.25rem",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 6, md: 12 }}
        >
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(COMMENTARY)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                comment
              </Icon>
            </IconButton>
            <span>{t("commentariesText")}</span>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(HIGHLIGHT)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                border_color
              </Icon>
            </IconButton>
            <span>{t("highlightsText")}</span>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(PARALLELBIBLE)}
            >
              <Icon
                style={{
                  fontSize: "60px",
                  textDecoration: "none",
                }}
                className={`material-icons`}
              >
                import_contacts
              </Icon>
            </IconButton>
            <span>{t("parallelBibleText")}</span>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(INFOGRAPHICS)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                image
              </Icon>
            </IconButton>
            <span>{t("infographicsText")}</span>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(READINGPLANS)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                event
              </Icon>
            </IconButton>
            <span>{t("readingPlansText")}</span>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(SIGNBIBLE)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                sign_language
              </Icon>
            </IconButton>
            <span>{t("ISLVBibleText")}</span>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    parallelView: state.local.parallelView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setParallelView: (value) =>
      dispatch({ type: SETVALUE, name: "parallelView", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FeaturesBox);
