import React from "react";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import screenshot from "../common/images/screenshot.jpg";
import playStore from "../common/images/playStore.png";
import bible_background from "../common/images/bible.jpeg";
import Link from "@mui/material/Link";
import { Box, useMediaQuery, Typography } from "@mui/material";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { VIDEO } from "../../store/views";
import BibleIndex from "../landing/BibleIndex";
import ImageCard from "./ImageCard";
import "./Landing.css";
import LandingFooter from "./LandingFooter";
import BigTooltip from "../common/BigTooltip";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import VerseOfTheDayCard from "./VerseOfTheDayCard";
import TopBar from "../read/TopBar";
import FeaturesBox from "./FeaturesBox";
import { BLACK } from "../../store/colorCode";
import BlogSection from "./BlogSection";
import NewsLetterSection from "./NewsLetterSection";
const ImageScreen = styled("img")(({ theme }) => ({
  width: "90%",
  display: "inline-block",
  marginBottom: 10,
  [theme.breakpoints.only("md")]: {
    width: "60%",
  },
}));
const ImagePlayStore = styled("img")(({ theme }) => ({
  width: "20%",
  margin: "20px 7%",
  maxWidth: 280,
  display: "inline-block",
  [theme.breakpoints.down("md")]: {
    width: "30%",
  },
}));
const Landing = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { setValue, mobileView } = props;
  const { t } = useTranslation();
  React.useEffect(() => {
    if (isMobile) {
      setValue("mobileView", true);
    } else {
      setValue("mobileView", false);
    }
  }, [isMobile, setValue]);
  const addLink = () => {
    return process.env.REACT_APP_DOWNLOAD_URL ? (
      <Link
        href={process.env.REACT_APP_DOWNLOAD_URL}
        target="_blank"
        underline="hover"
      >
        <BigTooltip title={t("landingPlayStoreToolTip")}>
          <ImagePlayStore src={playStore} alt={t("landingAlt")} />
        </BigTooltip>
      </Link>
    ) : (
      ""
    );
  };

  const docLink = isMobile
    ? process.env.REACT_APP_MOBILE_DOCUMENT_URL
    : process.env.REACT_APP_DOCUMENT_URL;

  return (
    <Grid sx={{ backgroundColor: "white" }}>
      <TopBar landingPage={true} />
      <VerseOfTheDayCard />
      <Grid
        container
        sx={{
          display: { sm: "flex" },
          marginTop: { xs: "40px", md: "70px" },
          marginBottom: { xs: "70px", md: "100px" },
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography
          gutterBottom
          variant="h3"
          sx={{ fontSize: { xs: "30px", md: "3rem" } }}
        >
          {t("landingSelectBibleHeading")}
        </Typography>
        <BibleIndex />
      </Grid>
      <Box
        sx={{
          backgroundImage: `url(${bible_background})`,
          display: "grid",
          height: "auto",
          alignContent: "space-evenly",
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <Box
          sx={{
            color: "white",
            justifySelf: "center",
            margin: "25px 0",
            textAlign: "center",
            padding: "10px",
            maxWidth: "1000px",
          }}
        >
          <Box sx={{ fontSize: { xs: "30px", sm: "2.5rem" } }}>
            {t("processRead")}
          </Box>
          <Box
            component="p"
            sx={{
              marginTop: 2,
              textShadow: "2px 2px " + BLACK,
              fontSize: { sm: "18px", md: "20px" },
            }}
          >
            {t("featuresDesc2")}{" "}
          </Box>
        </Box>
        <Grid
          container
          justifyContent="center"
          sx={{
            alignSelf: "self-end",
            justifyContent: "space-evenly",
            margin: { lg: "0px 30px 20px 30px", xs: 0.625 },
            width: "auto",
          }}
        >
          <ImageCard icon="volume_up" type="Listen" />
          <ImageCard
            icon="videocam"
            type="Watch"
            onClick={() => setValue("parallelView", VIDEO)}
          />
          <ImageCard icon="local_library" type="Read" />
          <ImageCard icon="menu_book" type="Study" />
        </Grid>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: { lg: "auto", xs: -1.25 },
          width: { md: "calc(100% - 30px)" },
          textAlign: "center",
        }}
      >
        <Grid item md={12} lg={6}>
          <Box
            sx={{
              fontSize: { lg: 20, xs: 16 },
              marginTop: { lg: 6.25, xs: 2.5 },
              marginRight: { lg: "auto", xs: 2.5 },
              marginBottom: { lg: "auto", xs: 2.5 },
              marginLeft: { lg: "auto", xs: 2.5 },
              paddingLeft: { md: 3.75 },
            }}
          >
            <Box
              component="h2"
              sx={{
                textAlign: "center",
                fontSize: { xs: "30px", sm: "2.5rem" },
              }}
            >
              {t("landingWelcomeHeading")}
            </Box>

            <p style={{ textAlign: "Justify" }}>
              {t("landingWelcomeMessage1")}
            </p>
            {!mobileView && (
              <>
                <p style={{ textAlign: "Justify" }}>
                  {t("landingWelcomeMessage2")}
                </p>
                <p style={{ textAlign: "Justify" }}>
                  {t("landingWelcomeMessage3")}
                </p>
              </>
            )}
          </Box>
        </Grid>
        <Grid item md={12} lg={6}>
          <Box
            sx={{ textAlign: "center", width: { sm: "100vw", md: "unset" } }}
          >
            <ImageScreen src={screenshot} alt="Screenshot" />
          </Box>
        </Grid>
      </Grid>
      <FeaturesBox />
      <BlogSection />
      <NewsLetterSection />
      <Box
        sx={{
          fontSize: { lg: 20, xs: 16 },
          marginRight: { lg: "auto", xs: 2.5 },
          marginBottom: { lg: "auto", xs: 2.5 },
          marginLeft: { lg: "auto", xs: 4.125 },
          height: "400px",
          textAlign: "center",
        }}
      >
        <h3 style={{ margin: "20px 0" }}>{t("downloadVachanGo")}</h3>
        <p>{t("bottomText1")}</p>
        <p>{t("bottomText2")}</p>
        <div>{addLink()}</div>
        <p> {t("bottomText3")}</p>
      </Box>
      <LandingFooter docLink={docLink} />
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    mobileView: state.local.mobileView,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setValue: (name, value) =>
      dispatch({ type: actions.SETVALUE, name: name, value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Landing);
