import React, { useEffect, useState, useRef } from "react";
import TopBar from "../read/TopBar";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Typography,
  useMediaQuery,
  Select,
} from "@mui/material/";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { connect } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import * as actions from "../../store/actions";
import ReactSelect from "react-select";
import { capitalize, getVersions } from "../common/utility";
import { API } from "../../store/api";
import { useTranslation } from "react-i18next";
import { BLACK, GREY } from "../../store/colorCode";
import { languageJson } from "../../store/languageData";
import BookCombo from "../common/BookCombo";

const CustomSelect = styled(ReactSelect)(({ theme }) => ({
  width: "200px",
  [theme.breakpoints.down("md")]: {
    width: "130px",
  },
}));

function Comparison(props) {
  const { t } = useTranslation();
  const [verses, setVerses] = useState([]);
  const [selectedVerseIndex, setSelectedVerseIndex] = useState(-1);
  const [sourceId, setSourceId] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [verseList, setVerseList] = useState([]);
  const [prevChapter, setPrevChapter] = useState(null);
  const [nextChapter, setNextChapter] = useState(null);
  const prevClicked = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [bookDisplay, setBookDisplay] = React.useState("");

  const {
    setVersions,
    setValue,
    panel1,
    versions,
    setVersionBooks,
    setMainValue,
    versionBooks,
  } = props;
  const { bookCode, chapter } = panel1;

  const bookList = versionBooks[selectedLanguage.value];

  useEffect(() => {
    let _version = localStorage.getItem("version");
    let _bookCode = localStorage.getItem("bookCode");
    let _chapter = localStorage.getItem("chapter");
    let _verseData = localStorage.getItem("verseData");
    // if versions not set fetch bible versions
    if (versions.length === 0) {
      getVersions(
        setVersions,
        setValue,
        setVersionBooks,
        setMainValue,
        _version,
        _bookCode,
        _chapter,
        _verseData
      );
      setSelectedVerseIndex(0);
    }
  }, [setVersions, setValue, setVersionBooks, setMainValue, versions.length]);

  React.useEffect(() => {
    if (bookList) {
      let book = bookList.find((element) => element.book_code === bookCode);
      if (book) {
        setBookDisplay(book.short);
      }
    }
  }, [bookList, bookCode, setBookDisplay]);

  useEffect(() => {
    if (Object.keys(versionBooks).length > 0) {
      let languageList = [];
      Object.keys(versionBooks).forEach((langVersion) => {
        let findLanguage = languageJson.find(
          (el) => langVersion === el.langCode
        );
        if (findLanguage) {
          languageList.push({
            value: findLanguage.langCode,
            label: capitalize(findLanguage.language),
          });
          const sourceCode = versions.find(
            (e) => e?.languageVersions[0]?.language?.code === "eng"
          )?.languageVersions[0]?.sourceId;
          setSourceId(sourceCode);
          if (findLanguage.langCode === "eng") {
            setSelectedLanguage({
              value: findLanguage.langCode,
              label: capitalize(findLanguage.language),
            });
          }
        }
      });
      setLanguages(languageList.sort((a, b) => a.label.localeCompare(b.label)));
    }
  }, [versionBooks, versions]);

  useEffect(() => {
    // fetch prev and next chapter details
    const prevNextChapter = () => {
      if (sourceId && bookCode && chapter) {
        let url = `bibles/${sourceId}/books/${bookCode}/chapter/${chapter}`;
        API.get(url)
          .then((res) => {
            setPrevChapter(res.data.previous);
            setNextChapter(res.data.next);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    // fetch verse list for current chapter
    const fetchVerseList = () => {
      let url = `bibles/${sourceId}/books/${bookCode}/chapters/${chapter}/verses`;
      API.get(url)
        .then((res) => {
          setVerses(res.data.map((a) => a.verse.verseId));
          setSelectedVerseIndex(() => {
            if (prevClicked.current) {
              prevClicked.current = false;
              return res.data.length - 1;
            } else {
              return 0;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (sourceId && bookCode && chapter) {
      prevNextChapter();
      fetchVerseList();
    }
  }, [sourceId, bookCode, chapter]);

  useEffect(() => {
    if (
      bookCode &&
      verses.length > 0 &&
      selectedVerseIndex !== -1 &&
      versions.length > 0 &&
      selectedLanguage
    ) {
      const fetchVerse = async () => {
        setIsLoading(true);
        setVerseList([]);
        let tmpVerseList = [];
        let promises = [];
        let findSelectedLanguage = versions.findIndex(
          (el) =>
            el.language.toLowerCase() === selectedLanguage.label.toLowerCase()
        );
        if (findSelectedLanguage !== -1) {
          for (let languageVersion of versions[findSelectedLanguage]
            .languageVersions) {
            let promise = API.get(
              `bibles/${languageVersion.sourceId}/verses/${bookCode}.${verses[selectedVerseIndex]}`
            )
              .then((res) => {
                if (res?.data?.verseContent?.text) {
                  tmpVerseList.push({
                    code: languageVersion?.version?.code,
                    verse: res?.data?.verseContent?.text,
                    langCode: languageVersion.language.code,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });

            promises.push(promise);
          }
        }
        try {
          await Promise.all(promises);
          setVerseList(tmpVerseList);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching verses:", error);
        }
      };
      fetchVerse();
    }
  }, [bookCode, versions, selectedVerseIndex, selectedLanguage, verses]);

  const prevVerse = () => {
    if (selectedVerseIndex !== 0) {
      setSelectedVerseIndex((prevValue) => prevValue - 1);
    } else {
      prevClicked.current = true;
      setVerses([]);
      if (prevChapter?.bibleBookCode) {
        setValue("chapter", prevChapter.chapterId);
        setValue("bookCode", prevChapter.bibleBookCode);
        setValue("sourceId", prevChapter.sourceId);
      }
    }
  };

  const nextVerse = () => {
    if (selectedVerseIndex + 1 < verses.length) {
      setSelectedVerseIndex((prevValue) => prevValue + 1);
    } else {
      setVerses([]);
      if (nextChapter?.bibleBookCode) {
        setValue("chapter", nextChapter.chapterId);
        setValue("bookCode", nextChapter.bibleBookCode);
        setValue("sourceId", nextChapter.sourceId);
      }
    }
  };

  const verseSelect = () =>
    verses && selectedVerseIndex !== -1 ? (
      <Select
        value={selectedVerseIndex}
        sx={{
          "& .MuiSelect-select": {
            "&:focus": {
              backgroundColor: "transparent",
            },
            padding: "10px 20px",
          },
        }}
      >
        {verses?.map((verseDetail, y) => (
          <MenuItem
            key={verseDetail}
            value={y}
            onClick={() => setSelectedVerseIndex(y)}
          >
            <Typography sx={{ width: "100%" }}>
              {verseDetail?.split(".")[1]}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    ) : (
      ""
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "auto",
          position: "fixed",
          width: "100%",
          zIndex: "9",
          background: "white",
          borderBottom: "1px solid #d4d4d4",
        }}
      >
        <TopBar />
        <Box
          sx={{
            width: "100%",
            marginTop: "82px",
            display: "flex",
            height: "100%",
            marginBottom: "10px",
            justifyContent: "space-around",
            [theme.breakpoints.down("md")]: {
              marginTop: "60px",
              marginBottom: "5px",
              flexDirection: "column-reverse",
            },
            [theme.breakpoints.only("xs")]: {
              marginTop: "65px",
              marginBottom: 0,
            },
          }}
        >
          <Box
            sx={{
              marginLeft: isMobile ? "-15px" : "-100px",
              display: "flex",
              minWidth: "325px",
              alignItems: "center",
              justifyContent: "space-around",
              [theme.breakpoints.only("sm")]: {
                paddingTop: "5px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "5px",
                alignItems: "center",
              }}
            >
              {languages && languages?.length !== 0 && (
                <CustomSelect
                  value={selectedLanguage}
                  onChange={(data) => {
                    const sourceCode = versions.find(
                      (e) =>
                        e?.languageVersions[0]?.language?.code === data.value
                    )?.languageVersions[0]?.sourceId;
                    setSourceId(sourceCode);
                    setSelectedLanguage(data);
                  }}
                  options={languages}
                />
              )}
              {!isMobile &&
                (prevChapter?.bibleBookCode || selectedVerseIndex !== 0) && (
                  <Box>
                    <KeyboardArrowLeftIcon
                      sx={{
                        border: "1px solid #a5a5a5",
                        borderRadius: "50%",
                      }}
                      size={26}
                      onClick={prevVerse}
                    />
                  </Box>
                )}
              {bookCode !== "" && bookCode !== undefined ? (
                <BookCombo
                  bookCode={bookCode}
                  bookList={versionBooks[selectedLanguage.value]}
                  chapter={chapter}
                  setValue={setValue}
                  minimal={true}
                />
              ) : (
                ""
              )}
              <FormControl variant="outlined" sx={{ minWidth: "30px" }}>
                {verseSelect()}
              </FormControl>
            </Box>
            {!isMobile &&
              (nextChapter?.bibleBookCode ||
                selectedVerseIndex !== verses.length - 1) && (
                <Box>
                  <KeyboardArrowRightIcon
                    sx={{
                      border: "1px solid #a5a5a5",
                      borderRadius: "50%",
                      padding: "3px",
                      marginLeft: "15px",
                    }}
                    size={26}
                    onClick={nextVerse}
                  />
                </Box>
              )}
          </Box>
          {isMobile ? <Divider /> : ""}
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            {isMobile &&
              (prevChapter?.bibleBookCode || selectedVerseIndex !== 0) && (
                <Box>
                  <KeyboardArrowLeftIcon
                    sx={{
                      border: "1px solid #a5a5a5",
                      borderRadius: "50%",
                    }}
                    size={26}
                    onClick={prevVerse}
                  />
                </Box>
              )}
            <Typography
              variant="h4"
              sx={{
                margin: "0 auto",
                fontSize: "1.7rem",
                [theme.breakpoints.down("md")]: {
                  margin: "0 10px",
                },
              }}
            >
              {t("comparisonTopBarBtn")}
            </Typography>
            {isMobile &&
              (nextChapter?.bibleBookCode ||
                selectedVerseIndex !== verses.length - 1) && (
                <Box>
                  <KeyboardArrowRightIcon
                    sx={{
                      border: "1px solid #a5a5a5",
                      borderRadius: "50%",
                      padding: "3px",
                    }}
                    size={26}
                    onClick={nextVerse}
                  />
                </Box>
              )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: "150px",
          display: "flex",
          flexDirection: "column",
          paddingInline: "7%",
          overflowX: "hidden",
          marginBottom: "20px",
          paddingBottom: "30px",
          [theme.breakpoints.only("xs")]: {
            paddingTop: "200px",
          },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            Loading...
          </Box>
        ) : verseList.length > 0 ? (
          verseList.map((verse) => {
            return (
              <Box
                key={verse.code}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    margin: "10px 0px",
                  }}
                >
                  {verse.code}:{" "}
                  <Typography
                    sx={{
                      display: "inline",
                      fontSize: "18px",
                      fontWeight: "400",
                      paddingBlock: "10px",
                      wordSpacing: "4px",
                    }}
                  >
                    {verse.verse}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    aria-controls="commentary-menu"
                    aria-haspopup="true"
                    onClick={() =>
                      (window.location.href = `/read?version=${verse.langCode}-${verse.code}&reference=${bookCode}.${chapter}`)
                    }
                    variant="contained"
                    sx={{
                      "&.MuiButton-root": {
                        margin: "5px 0px",
                        fontSize: "1rem",
                        textTransform: "capitalize",
                        backgroundColor: "#fff",
                        border: "1px solid #fff",
                        boxShadow: "1px 1px 1px 1px " + GREY,
                        color: BLACK,
                      },
                    }}
                  >
                    {t("readTopBarBtnMob")} {bookDisplay} {chapter} {verse.code}
                  </Button>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography>{t("noVerseAvailable")}</Typography>
        )}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    versions: state.local.versions,
    versionBooks: state.local.versionBooks,
    panel1: state.local.panel1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setValue: (name, value) =>
      dispatch({ type: actions.SETVALUE1, name: name, value: value }),
    setVersions: (value) =>
      dispatch({ type: actions.SETVERSIONS, value: value }),
    setVersionBooks: (name, value) =>
      dispatch({ type: actions.ADDVERSIONBOOKS, name: name, value: value }),
    setMainValue: (name, value) =>
      dispatch({ type: actions.SETVALUE, name: name, value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comparison);
