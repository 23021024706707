import { Box, Typography } from "@mui/material";
import { BLACK } from "../../store/colorCode";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import bible from "../common/images/bible.jpeg";
const BlogSection = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        fontSize: { lg: 20, xs: 16 },
        textAlign: "center",
        paddingBottom: "50px",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <h1> {t("blogTitle")}</h1>
        <h5>{t("blogSubHeading")}</h5>
      </Box>

      <Box
        justifyContent="center"
        sx={{
          display: "flex",
          fontSize: "large",
          }}
      >
        <Link
          to="https://vachan-blogs.vercel.app/posts/scripture_engagement"
          target="_blank"
          style={{ textDecoration: "none", color: BLACK }}
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia sx={{ height: 180 }} image={bible} title="bible" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
               >
                Deepen Your Connection: Engaging with Scripture
              </Typography>
              <Typography variant="body2">
                Have you ever felt like reading the Bible is more of a chore
                than a spiritual experience? ...
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Box>
    </Box>
  );
};

export default BlogSection;
