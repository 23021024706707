import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
const buildHash = process.env.REACT_APP_BUILD_VERSION;
const translationPath = `/locales/{{lng}}/translation.json?v=${buildHash}`;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbacklng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    loadPath: translationPath,
  });

export default i18n;
