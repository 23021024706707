import { Box, TextField, Button, Snackbar, Alert } from "@mui/material";
import { LIGHTGREY, DARKORANGE, WHITE } from "../../store/colorCode";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../firebase/firebaseConfig";

const NewsLetterSection = () => {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const signUp = async () => {
    if (email && userName) {
      let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      if (!emailRegex.test(email)) {
        setAlert("error");
        setMessage(t("invalidEmailMsg"));
        return;
      }
      const docData = {
        email: email,
        name: userName,
        created_time: new Date(),
      };
      await setDoc(doc(db, "emails", userName), docData);
      setAlert("success");
      setMessage(t("subscribeSuccessMsg"));
    } else {
      setAlert("error");
      setMessage(t("emptyNewsletterFormMsg"));
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert("");
    setMessage("");
  };

  return (
    <Box
      sx={{
        fontSize: { lg: 20, xs: 16 },
        textAlign: "center",
        backgroundColor: LIGHTGREY,
        paddingBottom: "20px",
      }}
    >
      <Box sx={{ padding: "40px 40px 0 40px" }}>
        <h1> {t("newsLetterTitle")}</h1>
        <h5> {t("newsLetterCaption")}</h5>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          paddingY: "40px",
        }}
      >
        <TextField
          required
          sx={{ width: "350px", padding: "10px" }}
          name="name"
          type="text"
          id="name"
          placeholder={t("userName")}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <TextField
          required
          sx={{ width: "350px", padding: "10px" }}
          id="email"
          name="email"
          placeholder={t("loginEmailPlaceHolder")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          variant="outlined"
          onClick={signUp}
          sx={{
            backgroundColor: DARKORANGE,
            color: WHITE,
            fontSize: "18px",
            height: "56px",
            width: "150px",
            marginY: "auto",
            borderColor: DARKORANGE,
            "&:hover": {
              backgroundColor: DARKORANGE,
              color: WHITE,
              border: "1px solid rgba(247,101,62,255)",
            },
          }}
        >
          {t("loginSubmit")}
        </Button>
      </Box>
      {alert ? (
        <Snackbar
          open={Boolean(alert)}
          autoHideDuration={8000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert variant="filled" onClose={handleClose} severity={alert}>
            {message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </Box>
  );
};

export default NewsLetterSection;
